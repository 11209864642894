<template>
  <div class="flex min-h-screen bg-gray-50 flex-col">
    <div class="relative" style="background-color: #0e1b39">
      <div class="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
        <div class="pr-16 sm:text-center sm:px-16">
          <p class="font-medium text-white">
            Palize, Inc is now
            <span class="inline-block"
              ><svg
                class="h-2"
                viewBox="0 0 262 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M29.6934 0.0175781H8.41895V8.43648H0V32.3135H8.41895V24.5932H29.6934C31.9262 24.5932 34.0676 23.7062 35.6464 22.1273C37.2253 20.5485 38.1123 18.4071 38.1123 16.1743V8.43648C38.1123 6.20364 37.2253 4.06228 35.6464 2.48342C34.0676 0.904567 31.9262 0.0175781 29.6934 0.0175781V0.0175781ZM29.6934 14.8293C29.6936 15.1725 29.561 15.5024 29.3233 15.7499C29.0855 15.9974 28.7612 16.1432 28.4183 16.1568H8.41895V8.43648H28.331C28.6863 8.441 29.0257 8.58416 29.277 8.83541C29.5282 9.08666 29.6714 9.42615 29.6759 9.78145L29.6934 14.8293Z"
                  fill="white"
                />
                <path
                  d="M82.3728 16.1743V8.43648C82.3728 6.20364 81.4859 4.06228 79.907 2.48342C78.3281 0.904567 76.1867 0.0175781 73.9539 0.0175781H44.7845V32.3135H53.2035V24.5932H70.3034L73.9539 32.3135H82.3728L78.1809 23.4404C79.4562 22.704 80.5151 21.6448 81.251 20.3693C81.9869 19.0937 82.3738 17.6469 82.3728 16.1743V16.1743ZM66.321 16.1743H53.2035V8.45396H72.5915C72.9498 8.45393 73.2937 8.59508 73.5487 8.84681C73.8037 9.09855 73.9493 9.44058 73.9539 9.79888V14.8468C73.954 15.1929 73.819 15.5253 73.5775 15.7733C73.3361 16.0213 73.0074 16.1652 72.6614 16.1743H66.3734H66.321Z"
                  fill="white"
                />
                <path
                  d="M119.228 0.0175962H97.9882C95.7553 0.0175962 93.6139 0.904585 92.0351 2.48344C90.4562 4.0623 89.5692 6.20365 89.5692 8.4365V23.8945C89.5692 26.1274 90.4562 28.2688 92.0351 29.8476C93.6139 31.4265 95.7553 32.3135 97.9882 32.3135H119.228C120.335 32.3158 121.431 32.0997 122.455 31.6776C123.478 31.2555 124.408 30.6358 125.192 29.8538C125.976 29.0718 126.597 28.1429 127.022 27.1204C127.446 26.0978 127.664 25.0016 127.664 23.8945V8.4365C127.664 7.32943 127.446 6.23327 127.022 5.21069C126.597 4.18812 125.976 3.25926 125.192 2.47726C124.408 1.69526 123.478 1.07547 122.455 0.653407C121.431 0.231344 120.335 0.0152994 119.228 0.0175962V0.0175962ZM119.228 22.5496C119.228 22.9063 119.086 23.2484 118.834 23.5006C118.582 23.7528 118.239 23.8945 117.883 23.8945H99.3331C98.9764 23.8945 98.6343 23.7528 98.3821 23.5006C98.1299 23.2484 97.9882 22.9063 97.9882 22.5496V9.78146C97.9882 9.42477 98.1299 9.08264 98.3821 8.83041C98.6343 8.57819 98.9764 8.4365 99.3331 8.4365H117.883C118.239 8.4365 118.582 8.57819 118.834 8.83041C119.086 9.08264 119.228 9.42477 119.228 9.78146V22.5496Z"
                  fill="white"
                />
                <path
                  d="M157.602 8.43641H172.431V0H134.336V8.43641H149.183V32.3134H157.602V8.43641Z"
                  fill="white"
                />
                <path
                  d="M262 0V32.3134H251.083L232.324 10.0084V32.3134H223.905V0H234.804L253.581 22.305V0H262Z"
                  fill="white"
                />
                <path
                  d="M208.29 0.0175781H187.54C185.307 0.0175781 183.166 0.904567 181.587 2.48342C180.008 4.06228 179.121 6.20364 179.121 8.43648V23.8945C179.121 26.1273 180.008 28.2687 181.587 29.8476C183.166 31.4265 185.307 32.3135 187.54 32.3135H208.29C210.523 32.3135 212.664 31.4265 214.243 29.8476C215.822 28.2687 216.709 26.1273 216.709 23.8945V8.43648C216.709 6.20364 215.822 4.06228 214.243 2.48342C212.664 0.904567 210.523 0.0175781 208.29 0.0175781V0.0175781ZM208.29 22.5496C208.286 22.9049 208.143 23.2443 207.891 23.4956C207.64 23.7468 207.301 23.89 206.945 23.8945H188.902C188.544 23.8945 188.2 23.7534 187.945 23.5017C187.69 23.2499 187.544 22.9079 187.54 22.5496V9.78145C187.544 9.42315 187.69 9.08106 187.945 8.82933C188.2 8.57759 188.544 8.43645 188.902 8.43648H206.945C207.301 8.441 207.64 8.58416 207.891 8.83541C208.143 9.08666 208.286 9.42615 208.29 9.78145V22.5496Z"
                  fill="white"
                />
              </svg> </span
            >. More information in the coming months.
          </p>
        </div>
      </div>
    </div>
    <div v-show="showAnnouncement" class="relative bg-orange-600">
      <div class="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
        <div class="pr-16 sm:text-center sm:px-16">
          <p class="font-medium text-white">
            Are you losing revenue due to poor pagespeed?
            <span class="inline-block">
              <router-link to="/pagespeed" class="text-white font-bold underline">
                Find Out
                <span class="inline-block">
                  <component :is="ArrowRightIcon" class="h-4"></component>
                </span>
              </router-link>
            </span>
          </p>
        </div>
        <div
          class="absolute inset-y-0 right-0 pt-1 pr-1 flex items-start sm:pt-1 sm:pr-2 sm:items-start"
        >
          <button
            @click="hideAnnouncement()"
            type="button"
            class="flex p-2 rounded-md hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-white"
          >
            <span class="sr-only">Dismiss</span>
            <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
    <div class="relative bg-gray-50 overflow-hidden">
      <div class="relative pt-6">
        <Popover>
          <div class="max-w-7xl mx-auto px-4 sm:px-6 py-2">
            <nav
              class="relative flex items-center justify-between sm:h-10 md:justify-center"
              aria-label="Global"
            >
              <div class="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0">
                <div class="flex items-center justify-between w-full md:w-auto">
                  <router-link to="/">
                    <span class="sr-only">Palize</span>
                    <svg
                      class="h-8 w-auto sm:h-10"
                      viewBox="0 0 74 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M61.2 13.75C61.2 17.2018 63.9982 20 67.45 20C69.7982 20 71.8438 18.7051 72.9119 16.7904C72.9925 16.646 72.9317 16.4659 72.7838 16.3919L69.967 14.9835C69.818 14.909 69.6382 14.9715 69.5473 15.1112C69.1015 15.7967 68.3287 16.25 67.45 16.25C66.0693 16.25 64.95 15.1307 64.95 13.75H61.2Z"
                        fill="#FFB762"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M73.7 13.75C73.7 10.2982 70.9018 7.5 67.45 7.5C63.9982 7.5 61.2 10.2982 61.2 13.75H73.7Z"
                        fill="#EA830A"
                      />
                      <path
                        d="M52.45 11.6667L46.2 15.8333H52.45L58.7 11.6667H52.45Z"
                        fill="#FFB762"
                      />
                      <path
                        d="M56.2 7.5H46.2C46.2 9.80119 48.0655 11.6667 50.3667 11.6667H58.7V10C58.7 8.61929 57.5807 7.5 56.2 7.5Z"
                        fill="#EA830A"
                      />
                      <path
                        d="M46.2 17.5V15.8333H54.5334C56.8345 15.8333 58.7 17.6988 58.7 20H48.7C47.3193 20 46.2 18.8807 46.2 17.5Z"
                        fill="#EA830A"
                      />
                      <path
                        d="M38.7 10C38.7 8.61929 39.8193 7.5 41.2 7.5C42.5807 7.5 43.7 8.61929 43.7 10V20H38.7V10Z"
                        fill="#FFB762"
                      />
                      <rect x="38.7" width="5" height="5" rx="2.5" fill="#EA830A" />
                      <path
                        d="M31.2 0C33.9615 0 36.2 2.23858 36.2 5V20H31.2V0Z"
                        fill="#EA830A"
                      />
                      <circle cx="22.25" cy="13.75" r="6.25" fill="#FFB762" />
                      <path
                        d="M24 10C24 8.61929 25.1193 7.5 26.5 7.5C27.8807 7.5 29 8.61929 29 10V20C26.2386 20 24 17.7614 24 15V10Z"
                        fill="#EA830A"
                      />
                      <path d="M0 15H5V20H0V15Z" fill="#FFB762" />
                      <path
                        d="M0 2.5C0 1.11929 1.11929 0 2.5 0H7.5C11.6421 0 15 3.35786 15 7.5C15 11.6421 11.6421 15 7.5 15H0V2.5Z"
                        fill="#EA830A"
                      />
                    </svg>
                  </router-link>
                  <div class="-mr-2 flex items-center md:hidden">
                    <PopoverButton
                      class="bg-gray-50 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-orange-500"
                    >
                      <span class="sr-only">Open main menu</span>
                      <MenuIcon class="h-6 w-6" aria-hidden="true" />
                    </PopoverButton>
                  </div>
                </div>
              </div>
              <div class="hidden md:flex md:space-x-10">
                <Popover class="relative" v-slot="{ open }">
                  <PopoverButton
                    :class="[
                      open ? 'text-gray-900' : 'text-gray-500',
                      'group inline-flex items-center text-base font-medium hover:text-gray-90',
                    ]"
                  >
                    <span>Services</span>
                    <ChevronDownIcon
                      :class="[
                        open ? 'text-gray-600' : 'text-gray-400',
                        'ml-2 h-5 w-5 group-hover:text-gray-500',
                      ]"
                      aria-hidden="true"
                    />
                  </PopoverButton>

                  <transition
                    enter-active-class="transition ease-out duration-200"
                    enter-from-class="opacity-0 translate-y-1"
                    enter-to-class="opacity-100 translate-y-0"
                    leave-active-class="transition ease-in duration-150"
                    leave-from-class="opacity-100 translate-y-0"
                    leave-to-class="opacity-0 translate-y-1"
                  >
                    <PopoverPanel
                      class="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md lg:max-w-3xl sm:px-0"
                      v-slot="{ close }"
                    >
                      <div
                        class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden"
                      >
                        <div
                          class="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-2"
                        >
                          <router-link
                            v-for="item in whatWeDo.links"
                            :key="item.name"
                            :to="item.href"
                            class="-m-3 p-3 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                          >
                            <span class="flex items-start" @click="close()">
                              <component
                                :is="item.icon"
                                class="flex-shrink-0 h-6 w-6 text-orange-600"
                                aria-hidden="true"
                              />
                              <div class="ml-4">
                                <p class="text-base font-medium text-gray-900">
                                  {{ item.name }}
                                </p>
                                <p class="mt-1 text-sm text-gray-500">
                                  {{ item.description }}
                                </p>
                              </div>
                            </span>
                          </router-link>
                        </div>
                        <div
                          class="px-5 py-5 bg-gray-50 space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8"
                        >
                          <div
                            v-for="item in whatWeDo.cta"
                            :key="item.name"
                            class="flow-root"
                          >
                            <router-link
                              :to="item.href"
                              class="block -m-3 p-3 rounded-md text-base font-medium text-gray-500 hover:bg-gray-100 transition ease-in-out duration-150"
                            >
                              <span class="flex items-center" @click="close()">
                                <component
                                  :is="item.icon"
                                  class="flex-shrink-0 h-6 w-6 text-gray-500"
                                  aria-hidden="true"
                                />
                                <span class="ml-3">{{ item.name }}</span>
                              </span>
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </PopoverPanel>
                  </transition>
                </Popover>
                <router-link
                  v-for="item in navigation"
                  :key="item.name"
                  :to="item.href"
                  class="font-medium text-gray-500 hover:text-gray-900"
                  >{{ item.name }}</router-link
                >
              </div>
              <div
                class="hidden md:absolute md:flex md:items-center md:justify-end md:inset-y-0 md:right-0"
              >
                <span class="inline-flex rounded-md shadow">
                  <router-link
                    to="/contact"
                    class="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-orange-600 bg-white hover:bg-gray-50"
                  >
                    Get Started
                  </router-link>
                </span>
              </div>
            </nav>
          </div>

          <transition
            enter-active-class="duration-150 ease-out"
            enter-from-class="opacity-0 scale-95"
            enter-to-class="opacity-100 scale-100"
            leave-active-class="duration-100 ease-in"
            leave-from-class="opacity-100 scale-100"
            leave-to-class="opacity-0 scale-95"
          >
            <PopoverPanel
              v-slot="{ close }"
              focus
              class="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
            >
              <div
                class="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden"
              >
                <div class="shadow-sm px-5 py-4 flex items-center justify-between">
                  <div>
                    <svg
                      class="h-8 w-auto sm:h-10"
                      viewBox="0 0 74 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M61.2 13.75C61.2 17.2018 63.9982 20 67.45 20C69.7982 20 71.8438 18.7051 72.9119 16.7904C72.9925 16.646 72.9317 16.4659 72.7838 16.3919L69.967 14.9835C69.818 14.909 69.6382 14.9715 69.5473 15.1112C69.1015 15.7967 68.3287 16.25 67.45 16.25C66.0693 16.25 64.95 15.1307 64.95 13.75H61.2Z"
                        fill="#FFB762"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M73.7 13.75C73.7 10.2982 70.9018 7.5 67.45 7.5C63.9982 7.5 61.2 10.2982 61.2 13.75H73.7Z"
                        fill="#EA830A"
                      />
                      <path
                        d="M52.45 11.6667L46.2 15.8333H52.45L58.7 11.6667H52.45Z"
                        fill="#FFB762"
                      />
                      <path
                        d="M56.2 7.5H46.2C46.2 9.80119 48.0655 11.6667 50.3667 11.6667H58.7V10C58.7 8.61929 57.5807 7.5 56.2 7.5Z"
                        fill="#EA830A"
                      />
                      <path
                        d="M46.2 17.5V15.8333H54.5334C56.8345 15.8333 58.7 17.6988 58.7 20H48.7C47.3193 20 46.2 18.8807 46.2 17.5Z"
                        fill="#EA830A"
                      />
                      <path
                        d="M38.7 10C38.7 8.61929 39.8193 7.5 41.2 7.5C42.5807 7.5 43.7 8.61929 43.7 10V20H38.7V10Z"
                        fill="#FFB762"
                      />
                      <rect x="38.7" width="5" height="5" rx="2.5" fill="#EA830A" />
                      <path
                        d="M31.2 0C33.9615 0 36.2 2.23858 36.2 5V20H31.2V0Z"
                        fill="#EA830A"
                      />
                      <circle cx="22.25" cy="13.75" r="6.25" fill="#FFB762" />
                      <path
                        d="M24 10C24 8.61929 25.1193 7.5 26.5 7.5C27.8807 7.5 29 8.61929 29 10V20C26.2386 20 24 17.7614 24 15V10Z"
                        fill="#EA830A"
                      />
                      <path d="M0 15H5V20H0V15Z" fill="#FFB762" />
                      <path
                        d="M0 2.5C0 1.11929 1.11929 0 2.5 0H7.5C11.6421 0 15 3.35786 15 7.5C15 11.6421 11.6421 15 7.5 15H0V2.5Z"
                        fill="#EA830A"
                      />
                    </svg>
                  </div>
                  <div class="-mr-2">
                    <PopoverButton
                      class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-orange-500"
                    >
                      <span class="sr-only">Close menu</span>
                      <XIcon class="h-6 w-6" aria-hidden="true" />
                    </PopoverButton>
                  </div>
                </div>
                <div class="px-2 pt-2 pb-3">
                  <router-link
                    v-for="item in navigation"
                    :key="item.name"
                    :to="item.href"
                    @click="close()"
                    class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                    >{{ item.name }}</router-link
                  >
                </div>
                <div
                  class="block w-full px-5 py-3 font-medium text-orange-600 bg-gray-50"
                >
                  <div class="grid gap-3 mt-3">
                    <a
                      v-for="item in whatWeDo.links"
                      :key="item.name"
                      :href="item.href"
                      class="pb-3 flex items-start rounded-lg"
                    >
                      <component
                        :is="item.icon"
                        class="flex-shrink-0 h-6 w-6 text-orange-600"
                        aria-hidden="true"
                      />
                      <div class="ml-4">
                        <p class="text-base font-medium text-gray-900">
                          {{ item.name }}
                        </p>
                        <p class="mt-1 text-sm text-gray-500">
                          {{ item.description }}
                        </p>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </PopoverPanel>
          </transition>
        </Popover>

        <router-view></router-view>
      </div>
    </div>
    <div class="flex-grow"></div>
    <div>
      <footer class="bg-white" aria-labelledby="footer-heading">
        <h2 id="footer-heading" class="sr-only">Footer</h2>
        <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div class="xl:grid xl:grid-cols-3">
            <div class="space-y-8 xl:col-span-1">
              <svg
                class="h-8 w-auto sm:h-10"
                viewBox="0 0 74 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M61.2 13.75C61.2 17.2018 63.9982 20 67.45 20C69.7982 20 71.8438 18.7051 72.9119 16.7904C72.9925 16.646 72.9317 16.4659 72.7838 16.3919L69.967 14.9835C69.818 14.909 69.6382 14.9715 69.5473 15.1112C69.1015 15.7967 68.3287 16.25 67.45 16.25C66.0693 16.25 64.95 15.1307 64.95 13.75H61.2Z"
                  fill="#FFB762"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M73.7 13.75C73.7 10.2982 70.9018 7.5 67.45 7.5C63.9982 7.5 61.2 10.2982 61.2 13.75H73.7Z"
                  fill="#EA830A"
                />
                <path
                  d="M52.45 11.6667L46.2 15.8333H52.45L58.7 11.6667H52.45Z"
                  fill="#FFB762"
                />
                <path
                  d="M56.2 7.5H46.2C46.2 9.80119 48.0655 11.6667 50.3667 11.6667H58.7V10C58.7 8.61929 57.5807 7.5 56.2 7.5Z"
                  fill="#EA830A"
                />
                <path
                  d="M46.2 17.5V15.8333H54.5334C56.8345 15.8333 58.7 17.6988 58.7 20H48.7C47.3193 20 46.2 18.8807 46.2 17.5Z"
                  fill="#EA830A"
                />
                <path
                  d="M38.7 10C38.7 8.61929 39.8193 7.5 41.2 7.5C42.5807 7.5 43.7 8.61929 43.7 10V20H38.7V10Z"
                  fill="#FFB762"
                />
                <rect x="38.7" width="5" height="5" rx="2.5" fill="#EA830A" />
                <path
                  d="M31.2 0C33.9615 0 36.2 2.23858 36.2 5V20H31.2V0Z"
                  fill="#EA830A"
                />
                <circle cx="22.25" cy="13.75" r="6.25" fill="#FFB762" />
                <path
                  d="M24 10C24 8.61929 25.1193 7.5 26.5 7.5C27.8807 7.5 29 8.61929 29 10V20C26.2386 20 24 17.7614 24 15V10Z"
                  fill="#EA830A"
                />
                <path d="M0 15H5V20H0V15Z" fill="#FFB762" />
                <path
                  d="M0 2.5C0 1.11929 1.11929 0 2.5 0H7.5C11.6421 0 15 3.35786 15 7.5C15 11.6421 11.6421 15 7.5 15H0V2.5Z"
                  fill="#EA830A"
                />
              </svg>
              <p class="text-gray-500 text-base">
                We help DTC merchants succeed by building beautiful stores that convert.
              </p>
              <div class="flex space-x-6">
                <a
                  v-for="item in footer.social"
                  :key="item.name"
                  :href="item.href"
                  target="_blank"
                  class="text-gray-400 hover:text-gray-500"
                >
                  <span class="sr-only">{{ item.name }}</span>
                  <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
                </a>
              </div>
            </div>
            <div class="xl:ml-auto mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
              <div class="md:grid md:grid-cols-2 md:gap-8">
                <div>
                  <h3
                    class="text-sm font-semibold text-gray-500 tracking-wider uppercase"
                  >
                    Services
                  </h3>
                  <ul role="list" class="mt-4 space-y-4">
                    <li v-for="item in footer.services" :key="item.name">
                      <router-link
                        :to="item.href"
                        class="text-base text-gray-500 hover:text-gray-900"
                      >
                        {{ item.name }}
                      </router-link>
                    </li>
                  </ul>
                </div>
                <div class="mt-12 md:mt-0">
                  <h3
                    class="text-sm font-semibold text-gray-500 tracking-wider uppercase"
                  >
                    Company
                  </h3>
                  <ul role="list" class="mt-4 space-y-4">
                    <li v-for="item in footer.company" :key="item.name">
                      <router-link
                        :to="item.href"
                        class="text-base text-gray-500 hover:text-gray-900"
                      >
                        {{ item.name }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="md:grid md:grid-cols-1 md:gap-8">
                <div>
                  <h3
                    class="text-sm font-semibold text-gray-500 tracking-wider uppercase"
                  >
                    Platforms
                  </h3>
                  <ul role="list" class="mt-4 space-y-4">
                    <li v-for="item in footer.platforms" :key="item.name">
                      <router-link
                        :to="item.href"
                        class="text-base text-gray-500 hover:text-gray-900"
                      >
                        {{ item.name }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-12 border-t border-gray-200 pt-8">
            <p class="text-base text-gray-400 xl:text-center">
              &copy; {{ new Date().getFullYear() }} Palize, Inc. All Rights Reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import { defineComponent, h } from "vue";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
import { MenuIcon, XIcon, ArrowRightIcon } from "@heroicons/vue/outline";
import { ChevronDownIcon } from "@heroicons/vue/solid";
import {
  ChartBarIcon,
  CursorClickIcon,
  CodeIcon,
  BeakerIcon,
} from "@heroicons/vue/outline";

const whatWeDo = {
  links: [
    {
      name: "Development",
      description:
        "Launch your storefront with confidence. Our team is certified experts in Shopify, Magento, and BigCommerce.",
      href: "/platforms/shopify-plus",
      icon: CodeIcon,
    },
    {
      name: "UI/UX Design",
      description:
        "Improve your user experience and your conversion rate. UI/UX is more than design, it is a science.",
      href: "/platforms/shopify-plus",
      icon: CursorClickIcon,
    },
    {
      name: "Analytics",
      description:
        "Get a better understanding of where your customers are coming from and what channels will power the future of your brand.",
      href: "#",
      icon: ChartBarIcon,
    },
    {
      name: "Marketing",
      description:
        "Amplify your acquisition, engagement, and retention with automation & AI powered digital marketing strategies.",
      href: "/paid-media",
      icon: BeakerIcon,
    },
  ],
  cta: [{ name: "Contact Us", href: "/contact", icon: null }],
};

const navigation = [
  { name: "Portfolio", href: "/case-studies" },
  { name: "Shopify Development", href: "/platforms/shopify-plus" },
  { name: "Developers", href: "/developers" },
  // { name: "Apps", href: "/apps" },
  { name: "Contact", href: "/contact" },
];

const footer = {
  services: [
    { name: "Development", href: "/platforms/shopify-plus" },
    { name: "UI/UX Design", href: "/platforms/shopify-plus" },
    { name: "Analytics", href: "#" },
    { name: "Marketing", href: "/paid-media" },
  ],
  platforms: [
    {
      name: "Shopify Plus",
      href: "/platforms/shopify-plus",
    },
    {
      name: "Magento",
      href: "/platforms/magento",
    },
    {
      name: "BigCommerce",
      href: "/platforms/bigcommerce",
    },
  ],
  company: [
    { name: "Blog", href: "#" },
    { name: "Jobs", href: "#" },
    { name: "Partners", href: "#" },
  ],
  social: [
    {
      name: "GitHub",
      href: "https://github.com/lynndigital",
      icon: defineComponent({
        render: () =>
          h("svg", { fill: "currentColor", viewBox: "0 0 24 24" }, [
            h("path", {
              "fill-rule": "evenodd",
              d:
                "M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z",
              "clip-rule": "evenodd",
            }),
          ]),
      }),
    },
  ],
};
export default {
  data() {
    return {
      showAnnouncement: true,
    };
  },
  components: {
    Popover,
    PopoverButton,
    PopoverPanel,
    ChevronDownIcon,
    MenuIcon,
    XIcon,
    ArrowRightIcon,
  },
  setup() {
    return {
      navigation,
      whatWeDo,
      footer,
    };
  },
  methods: {
    hideAnnouncement() {
      this.showAnnouncement = false;
    },
  },
};
</script>
