const partners = {
  logos: {
    home: [
      {
        website: 'https://aws.amazon.com',
        src: '/assets/Amazon_Web_Services_Logo.svg',
        alt: 'Amazon Web Services',
      },
      {
        website: 'https://shopify.com',
        src: '/assets/Shopify_logo_2018.svg',
        alt: 'Shopify',
      },
      {
        website: 'https://cloudflare.com',
        src: '/assets/Cloudflare.svg',
        alt: 'Cloudflare',
      },
      {
        website: 'https://magento.com',
        src: '/assets/Magento.svg',
        alt: 'Magento',
      }
    ],
    marketing: [
      {
        website: 'https://facebook.com',
        src: '/assets/platforms/facebook.svg',
        alt: 'Facebook'
      },
      {
        website: 'https://google.com',
        src: '/assets/platforms/google.svg',
        alt: 'Google'
      },
      {
        website: 'https://klaviyo.com',
        src: '/assets/platforms/klaviyo.png',
        alt: 'Klaviyo'
      },
      {
        website: 'https://marketo.com',
        src: '/assets/platforms/marketo.svg',
        alt: 'Marketo'
      },
    ],
    platforms: {
      shopify: [
        {
          website: "https://rechargepayments.com",
          src: "/assets/platforms/shopify/recharge.svg",
          alt: "ReCharge"
        },
        {
          website: "https://klaviyo.com",
          src: "/assets/platforms/shopify/klaviyo.svg",
          alt: "Klaviyo"
        },
        {
          website: "https://yotpo.com",
          src: "/assets/platforms/shopify/yotpo.svg",
          alt: "Klaviyo"
        },
        {
          website: "https://viacustomers.com",
          src: "/assets/platforms/shopify/viacustomers.svg",
          alt: "ViaCustomers"
        }
      ]
    }
  }
}

export { partners }