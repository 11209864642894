<template>
  <router-link :to="caseStudy.url" class="hover:text-orange-600">
    <img loading="lazy" :src="caseStudy.mobile_image" class="rounded-md md:hidden" />
    <img
      loading="lazy"
      :src="caseStudy.desktop_image"
      class="rounded-md hidden md:block"
    />
    <h3 class="text-3xl font-semibold">{{ caseStudy.title }}</h3>
    <p class="text-md text-gray-400 hover:text-gray-400">
      <img
        class="inline-block h-4"
        :src="'/assets/case-studies/icons/' + caseStudy.icon + '.svg'"
      />
      {{ caseStudy.subtitle }}
    </p>
  </router-link>
</template>

<script>
export default {
  props: {
    caseStudy: {
      url: "",
      title: "",
      subtitle: "",
      icon: "",
    },
  },
};
</script>
