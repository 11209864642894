<template>
  <section id="case-studies" class="bg-white">
    <div class="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
      <div class="text-center">
        <h2 class="text-base font-semibold text-orange-600 tracking-wide uppercase">
          {{ small_title }}
        </h2>
        <p
          class="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl"
        >
          {{ title }}
        </p>
        <p class="max-w-xl mt-5 mb-10 mx-auto text-xl text-gray-500">{{ subtitle }}</p>
      </div>

      <div class="grid gap-8 grid-cols-1 md:grid-cols-2">
        <case-study
          v-for="caseStudy in caseStudies"
          :key="caseStudy.title"
          :caseStudy="caseStudy"
        ></case-study>
      </div>
    </div>
  </section>
</template>

<script>
import CaseStudy from "./CaseStudy.vue";
export default {
  components: { CaseStudy },
  props: {
    caseStudies: [],
    small_title: "",
    title: "",
    subtitle: "",
  },
};
</script>
