import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/vue/outline'

const clients = [
  {
    handle: "dime-beauty-co",
    company: "DIME Beauty Co",
    description: 'Luxury skincare, beauty, and wellness products at affordable prices.',
    website: "https://dimebeautyco.com/?ref=palize.com",
    icon: "shopify",
    platform: "Shopify Plus",
    logo: '/assets/case-studies/dime.svg',
  },
  {
    handle: "puppy-love",
    company: "Puppy Love",
    description: 'PuppyLove is an international pet brand and official sponsor of Société Protectrice des Animaux (Protective Company of Animals). Through their world-class blankets and beds, they are giving better lives to animals all over France.',
    website: "https://puppylove.fr/?ref=palize.com",
    icon: "shopify",
    platform: "Shopify Plus",
    logo: '/assets/case-studies/puppylove.svg',
  },
  {
    handle: "muddy-bites",
    company: "Muddy Bites",
    description: null,
    website: "https://muddybites.com/?ref=palize.com",
    icon: "shopify",
    platform: "Shopify Plus",
    logo: null,
  },
  {
    handle: "skout-organic",
    company: "Skout Organic",
    description: 'Skout Organic is an Organic, Plant-Based, and Simple ingredient snack brand based out of Austin Texas.',
    website: "https://skoutorganic.com/?ref=palize.com",
    icon: "shopify",
    platform: "Shopify Plus",
    logo: '/assets/case-studies/skout-organic.svg'
  }
]

const caseStudies = [
  {
    // quote: {
    //   name: 'Marshall Ward',
    //   title: 'VP of Marketing',
    //   quote: 'Tincidunt integer commodo, cursus etiam aliquam neque, et. Consectetur pretium in volutpat, diam. Montes, magna cursus nulla feugiat dignissim id lobortis amet. Laoreet sem est phasellus eu proin massa, lectus.',
    //   picture: 'https://images.unsplash.com/photo-1500917293891-ef795e70e1f6?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2.5&w=160&h=160&q=80'
    // },
    quote: null,
    handle: "theme-build",
    clientName: "DIME Beauty Co",
    title: 'Theme Build',
    background: 'Luxury skincare, beauty, and wellness products at affordable prices.',
    brief: 'DIME Beauty approached Palize for a complete site redesign and rebrand due to the unreliability of their existing site and lack of performance.',
    goals: 'The site needed to engage both existing and new customers while delivering a seamless customer experience. The brands overarching objective was to build a highly performant and mobile-friendly site to fuel growth.',
    challenge: 'DIME was experiencing customer frustration due to slow site speed and an overall lack of mobile-friendliness. The DIME team also faced roadblocks while fueling growth due to the unreliability of their website. Integrating with new vendors and building features often required very extensive development work.',
    approach: 'Leveraging our extensive experience with luxury skincare brands and a thorough understanding of DIME, their product selection, and brand goals, we were able to design and build a performant and mobile-first site with a seamless user experience.',
    performance: [
      {
        value: '0.6s',
        icon: null,
        title: 'Largest Contentful Paint',
        description: 'How long it takes for the largest element of content (e.g a hero image) to be painted on your page.',
      },
      {
        value: '0.0s',
        icon: null,
        title: 'Total Blocking Time',
        description: 'How much time is blocked by scripts during your page loading process.',
      },
      {
        value: '1.8s',
        icon: null,
        title: 'Fully Loaded Time',
        description: 'How much time it takes for all elements to be painted on your page and all assets loaded.',
      },
    ],
    gallery: [
      '/assets/case-studies/dime-theme/1.png',
      '/assets/case-studies/dime-theme/2.png',
      '/assets/case-studies/dime-theme/3.png',
      '/assets/case-studies/dime-theme/4.png',
    ],
    industries: [
      'DTC', 'Beauty / Skincare', 'Luxury',
    ],
    services: [
      'Commerce Enablement', 'Commerce Personalization', 'Customer Experience (Cx)',
    ]
  },
  {
    quote: null,
    handle: "cart-discount-app",
    clientName: "DIME Beauty Co",
    title: 'Cart Discount App',
    background: 'Luxury skincare, beauty, and wellness products at affordable prices.',
    brief: "DIME Beauty Co attempted to use nearly every Cart Discount App that is available on the Shopify Appstore without success. Each app came with it's own set of issues that complicated development and growth of their website even more. ",
    goals: null,
    challenge: null,
    approach: 'We built DIME an Integrated Shopify App powered by Cloudflare Workers that allows customers to input discount codes in the cart and the mini cart. We were able to achieve this without any page redirects or Shopify Plus dependencies.',
    performance: [],
    gallery: [],
    industries: [
      'DTC', 'Beauty / Skincare', 'Luxury',
    ],
    services: [
      'Commerce Enablement', 'Commerce Integrations',
    ]
  },
  {
    public: false,
    quote: null,
    handle: "checkout-design",
    clientName: "Muddy Bites",
    title: 'Checkout Design',
    background: 'Luxury skincare, beauty, and wellness products at affordable prices.',
    brief: null,
    goals: null,
    challenge: null,
    approach: null,
    performance: [],
    gallery: [
      '/assets/case-studies/muddybites-checkout/1.png'
    ],
    industries: [
      'DTC', 'Food',
    ],
    services: [
      'Commerce Enablement', 'Customer Experience (Cx)',
    ]
  },
  {
    quote: null,
    handle: "theme-build",
    clientName: "Puppy Love",
    title: 'Theme Build',
    background: 'PuppyLove is an international pet brand and official sponsor of Société Protectrice des Animaux (Protective Company of Animals). Through their world-class blankets and beds, they are giving better lives to animals all over France.',
    brief: 'PuppyLove approached Palize for a complete site redesign and rebuild that would support the launch of a new product and empower the rollout of global language-specific stores.',
    goals: 'The site needed to educate and engage both new and existing customers while delivering a seamless customer experience. The brands overarching objective was to roll out a new product with the new site and create global stores expanding the reach of the brand.',
    challenge: 'PuppyLove was experiencing administrative frustration having minimal control over the layout and design of their Shopify Theme. The PuppyLove team also faced roadblocks while trying to expand their product line as their existing site was specifically built for their existing products. In only four weeks Palize build a performant Shopify Theme that surpassed Customer Experience (Cx) expectations and successfully rolled out on six global stores.',
    approach: 'Leveraging our extensive experience in the pet space and thorough understanding of Global Commerce, we were able to build a performant and mobile-first site with a seamless user experience.',
    performance: [
      {
        value: '1.2s',
        icon: null,
        title: 'Largest Contentful Paint',
        description: 'How long it takes for the largest element of content (e.g a hero image) to be painted on your page.',
      },
      {
        value: '132ms',
        icon: null,
        title: 'Total Blocking Time',
        description: 'How much time is blocked by scripts during your page loading process.',
      },
      {
        value: '1.9s',
        icon: null,
        title: 'Fully Loaded Time',
        description: 'How much time it takes for all elements to be painted on your page and all assets loaded.',
      },
    ],
    gallery: [
      '/assets/case-studies/puppylove-theme/1.png',
    ],
    industries: [
      'DTC', 'Pets',
    ],
    services: [
      'Global Commerce Enablement', 'Customer Experience (Cx)',
    ]
  },
  {
    quote: {
      title: 'Chief Snacking Officer',
      name: 'Mark Collis',
      picture: '/assets/case-studies/skout-organic.svg', 
      quote: 'Jake and his team absolutely crushed this project. We had other developers look at this project and none of them seemed to understand what we needed but Jake and his team understood and delivered. Palize delivered on what other agencies told us would be impossible.'
    },
    handle: "custom-bundle-builder",
    clientName: "Skout Organic",
    title: 'Custom Bundle Builder',
    background: 'Skout Organic is an Organic, Plant-Based, and Simple ingredient snack brand based out of Austin Texas.',
    brief: 'Skout approached Palize for a complete rebuild of their custom bundle builder that was built off of Bold Subscriptions and Bold Bundles.',
    challenge: 'There were no other apps on the market that allowed us to offer a build your own box for both subscriptions and one time purchases. After about 6 months of searching we reached out to Jake with Palize and he offered a solution.',
    goals: 'Offer a Build a Box solution where customers could select individual bars to fill their boxes of certain quantities based on the bar type (Protein or Kids) in the box. We also wanted to offer upsells and modifications to the box throughout the workflow.',
    approach: 'Leveraging our extensive experience in the health food space we were able to design and build and incredibly performant and engaging bundle builder that is integrated with ReCharge Payments.',
    performance: [
      {
        value: '1.0s',
        icon: null,
        title: 'Largest Contentful Paint',
        description: 'How long it takes for the largest element of content (e.g a hero image) to be painted on your page.',
      },
      {
        value: '0ms',
        icon: null,
        title: 'Total Blocking Time',
        description: 'How much time is blocked by scripts during your page loading process.',
      },
      {
        value: '1.0s',
        icon: null,
        title: 'Fully Loaded Time',
        description: 'How much time it takes for all elements to be painted on your page and all assets loaded.',
      },
    ],
    gallery: [
      '/assets/case-studies/skout-bundle-builder/1.jpg',
      '/assets/case-studies/skout-bundle-builder/2.jpeg',
      '/assets/case-studies/skout-bundle-builder/3.jpeg',
      '/assets/case-studies/skout-bundle-builder/4.jpeg',
      '/assets/case-studies/skout-bundle-builder/5.jpeg',
      '/assets/case-studies/skout-bundle-builder/6.jpeg',
      '/assets/case-studies/skout-bundle-builder/7.jpeg',
      '/assets/case-studies/skout-bundle-builder/8.jpeg',
    ],
    slidesPerView: 3,
    showFeaturedImage: false,
    industries: [
      'DTC', 'Lifestyle', 'Health',
    ],
    services: [
      'Commerce Enablement', 'Customer Experience (Cx)',
    ]
  }
]

for(let study of caseStudies) {
  study.client = clients.find(client => client.company === study.clientName)
}

export {clients, caseStudies}