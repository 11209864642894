import * as data  from './clients'

const caseStudysByClient = (clientHandle) => {
  let client = data.clients.find(client => client.handle === clientHandle)

  return data.caseStudies.filter(study => study.clientName === client.company)
}

const caseStudysByHandle = (clientHandle, studyHandle) => {
  return caseStudysByClient(clientHandle).find(study => study.handle === studyHandle)
}

const clientByHandle = (clientHandle) => {
  return data.clients.find(client => client.handle === clientHandle)
}

export { caseStudysByClient, caseStudysByHandle, clientByHandle }