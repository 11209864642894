<template>
  <main class="my-40 md:my-48 mx-auto max-w-6xl px-4">
    <div class="text-center">
      <h1
        class="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl"
      >
        <span class="block xl:inline"
          >Pioneering <span class="text-orange-600">e-commerce</span> one merchant at a
          time</span
        >
      </h1>
      <p
        class="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl"
      >
        We are a team of designers, developers, and marketers that specialize in building
        high-performance, beautiful storefronts that convert as well as powerful software
        that solves complex business problems
      </p>
      <div class="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
        <div class="rounded-md shadow">
          <a
            href="#case-studies"
            class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 md:py-4 md:text-lg md:px-10"
          >
            See the case studies
          </a>
        </div>
      </div>
    </div>
  </main>

  <logo-grid
    small_title="Partnered with the best"
    :logos="partners.logos.home"
  ></logo-grid>

  <case-study-grid
    :caseStudies="caseStudies"
    small_title="Case Studies"
    title="You're in good company."
    subtitle="We work with merchants that vary in size across all sorts of different industries and niches."
  ></case-study-grid>

  <section class="py-12 bg-gray-50 overflow-hidden md:py-20 lg:py-24">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <swiper
        :modules="[Pagination]"
        :slides-per-view="1"
        :pagination="{ el: '.js-swiper-pagination', clickable: true }"
      >
        <swiper-slide v-for="review in reviews" :key="review.name">
          <img
            loading="lazy"
            class="mx-auto h-14"
            :src="review.logo"
            :alt="review.company"
          />
          <blockquote class="mt-10">
            <div
              class="max-w-3xl mx-auto text-center text-2xl leading-9 font-medium text-gray-900"
            >
              <p>&ldquo;{{ review.quote }}&rdquo;</p>
            </div>
            <footer class="mt-8">
              <div class="md:flex md:items-center md:justify-center">
                <div class="md:flex-shrink-0">
                  <img
                    loading="lazy"
                    class="mx-auto h-10 w-10 rounded-full"
                    :src="review.picture"
                    :alt="review.name"
                  />
                </div>
                <div class="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                  <div class="text-base font-medium text-gray-900">{{ review.name }}</div>

                  <svg
                    class="hidden md:block mx-1 h-5 w-5 text-orange-600"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M11 0h3L9 20H6l5-20z" />
                  </svg>

                  <div class="text-base font-medium text-gray-500">
                    {{ review.company }}
                  </div>
                </div>
              </div>
            </footer>
          </blockquote>
        </swiper-slide>

        <div
          class="js-swiper-pagination mx-auto mt-8"
          style="width: fit-content !important"
        ></div>
      </swiper>
    </div>
  </section>

  <div class="bg-white overflow-hidden">
    <div class="relative max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
      <div class="relative flex flex-col-reverse lg:grid lg:grid-cols-3 lg:gap-x-8">
        <dl
          class="mt-10 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-8 sm:gap-y-10 lg:mt-0 lg:col-span-2"
        >
          <div v-for="feature in features" :key="feature.name">
            <dt>
              <div
                class="flex items-center justify-center h-12 w-12 rounded-md bg-orange-600 text-white"
              >
                <component :is="feature.icon" class="h-6 w-6" aria-hidden="true" />
              </div>
              <p class="mt-5 text-lg leading-6 font-medium text-gray-900">
                {{ feature.name }}
              </p>
            </dt>
            <dd class="mt-2 text-base text-gray-500">
              {{ feature.description }}
            </dd>
          </div>
        </dl>
        <div class="lg:col-span-1">
          <h2 class="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Building the future of e-commerce.
          </h2>
          <p class="text-gray-500 text-lg mt-4">
            Ecommerce is rapidly evolving, so is the way that customers shop. How do you
            keep up with
            {{ new Date().getFullYear() }} trends?
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="bg-gray-50">
    <div class="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
      <div class="space-y-12">
        <h2 class="text-3xl font-extrabold tracking-tight sm:text-4xl">
          Meet our leadership
        </h2>
        <ul
          role="list"
          class="space-y-12 lg:grid lg:grid-cols-4 lg:items-start lg:gap-x-8 lg:gap-y-12 lg:space-y-0"
        >
          <li v-for="person in people" :key="person.name">
            <div>
              <div class="h-0 aspect-w-3 aspect-h-3">
                <img
                  loading="lazy"
                  class="object-cover shadow-lg rounded-lg"
                  :src="person.imageUrl"
                  :alt="person.name"
                />
              </div>
              <div class="text-lg leading-6 font-medium space-y-1">
                <h3>{{ person.name }}</h3>
                <p class="text-orange-600">{{ person.role }}</p>
              </div>
              <div class="text-lg">
                <p class="text-gray-500">{{ person.bio }}</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {
  MailIcon,
  PhoneIcon,
  ChartBarIcon,
  GlobeIcon,
  CurrencyDollarIcon,
  DeviceMobileIcon,
} from "@heroicons/vue/outline";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import { default as CaseStudyGrid } from "../components/Home/CaseStudyGrid.vue";
import LogoGrid from "../components/LogoGrid.vue";

import { partners } from "../data/partners";

const features = [
  {
    name: "Performance Driven",
    description:
      "Based on case studies from Shopify and Skilled we now know that for every second a site takes to load almost 7% of conversions are lost. Our top priority during development is ensuring our projects pass all Core Web Vitals measurements.",
    icon: ChartBarIcon,
  },
  {
    name: "Mobile First",
    description:
      "Mobile devices account for nearly 95% of traffic for most e-commerce sites yet most agencies still tend to build primarily for desktop. We design and build mobile-first, once that is done we can cater to desktop devices.",
    icon: DeviceMobileIcon,
  },
  {
    name: "Conversion Optimized",
    description:
      "UI/UX is more than just designing, it is a science. Throughout our design process we are doing research and testing to ensure the experience we build is optimized for conversions. After launch we A/B test using Google Optimize to further optimize.",
    icon: CurrencyDollarIcon,
  },
  {
    name: "100% Accessible",
    description:
      "As many as 3% of customers are visually impared, 94% of e-commerce sites are not accessible. We design and build with accessibility in mind, our goal is for all customers to be able to shop seamlessly.",
    icon: GlobeIcon,
  },
];

const people = [
  {
    name: "David Morales",
    role: "Head of Visual Development",
    imageUrl: "/assets/team/david.png",
    bio: "",
  },
  {
    name: "Jake Casto",
    role: "Head of Operations",
    imageUrl: "/assets/team/jake.png",
    bio: "",
  },
  {
    name: "Ryan Walden",
    role: "Creative Director",
    imageUrl: "/assets/team/ryan.png",
    bio: "",
  },
  {
    name: "Emilio Franco",
    role: "Head of Engineering",
    imageUrl: "/assets/team/emilio.png",
    bio: "",
  },
];

const caseStudies = [
  {
    title: "DIME Beauty",
    subtitle: "Shopify Plus",
    icon: "shopify",
    desktop_image: "/assets/case-studies/dime-theme/1.png",
    mobile_image: "/assets/case-studies/dime-theme/1.png",
    url: "/clients/dime-beauty-co",
  },
  {
    title: "Puppy Love",
    subtitle: "Shopify Plus",
    icon: "shopify",
    desktop_image: "/assets/case-studies/puppylove-theme/1.png",
    mobile_image: "/assets/case-studies/puppylove-theme/1.png",
    url: "/clients/puppy-love/case-studies/theme-build",
  },
  {
    title: "Davids Bridal",
    subtitle: "Magento Enterprise",
    icon: "magento",
    desktop_image: "/assets/case-studies/dbi/1.png",
    mobile_image: "/assets/case-studies/dbi/1.png",
    url: "https://davidsbridal.com/?ref=palize.com",
  },
  {
    title: "Burlix",
    subtitle: "BigCommerce",
    icon: "bigcommerce",
    desktop_image: "/assets/case-studies/burlix/1.png",
    mobile_image: "/assets/case-studies/burlix/1.png",
    url: "https://burlix.com?ref=palize.com",
  },
  {
    title: "Shelly Cove",
    subtitle: "Shopify Plus",
    icon: "shopify",
    desktop_image: "/assets/case-studies/shellycove/1.png",
    mobile_image: "/assets/case-studies/shellycove/1.png",
    url: "https://shellycove.com?ref=palize.com",
  },
  {
    title: "Hue",
    subtitle: "Magento Enterprise",
    icon: "magento",
    desktop_image: "/assets/case-studies/hue/1.png",
    mobile_image: "/assets/case-studies/hue/1.png",
    url: "https://hue.com/?ref=palize.com",
  },
  // {
  //   title: "Muddy Bites",
  //   subtitle: "Shopify Plus",
  //   icon: "shopify",
  //   desktop_image: "/assets/case-studies/muddybites-checkout/1.png",
  //   mobile_image: "/assets/case-studies/muddybites-checkout/1.png",
  //   url: "/clients/muddy-bites/checkout-design",
  // },
];

const reviews = [
  {
    name: "Justin Maddahi",
    company: "Oral Essentials",
    quote:
      "Palize was able to complete very complicated custom coded tasks with our Shopify Plus store that other agencies said were impossible.",
    logo: "assets/reviews/oralessentials.svg",
    picture: "/assets/reviews/1.png",
  },
  // {
  //   company: "Skout Organic",
  //   name: "Mark Collis",
  //   logo: "/assets/case-studies/skout-organic.svg",
  //   picture: "/assets/case-studies/skout-organic.svg",
  //   quote:
  //     "Jake and his team absolutely crushed this project. We had other developers look at this project and none of them seemed to understand what we needed but Jake and his team understood and delivered. Palize delivered on what other agencies told us would be impossible.",
  // },
];

export default {
  components: {
    MailIcon,
    PhoneIcon,
    Swiper,
    SwiperSlide,
    CaseStudyGrid,
    LogoGrid,
  },
  setup() {
    return {
      caseStudies,
      features,
      people,
      reviews,
      Pagination,
      partners,
    };
  },
};
</script>
