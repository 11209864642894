import { createRouter, createWebHistory } from 'vue-router';

/**
* Primary Pages
*/
import { default as Home } from './pages/Home.vue';
import { default as NotFound } from './pages/NotFound.vue';

const Contact = () => import(/* webpackChunkName: "Contact" */ './pages/Contact.vue')
const Developers = () => import(/* webpackChunkName: "Developers" */ './pages/Developers.vue')
const Apps = () => import(/* webpackChunkName: "Apps" */ './pages/Apps.vue')
const Pagespeed = () => import(/* webpackChunkName: "Pagespeed" */ './pages/PagespeedTool.vue')
const Portfolio = () => import(/* webpackChunkName: "Portfolio" */ './pages/Portfolio.vue')
const Client = () => import(/* webpackChunkName: "Client" */ './pages/Client.vue')
const CaseStudy = () => import(/* webpackChunkName: "CaseStudy" */ './pages/CaseStudy.vue')
const PaidMedia = () => import(/* webpackChunkName: "PaidMedia" */ './pages/PaidMedia.vue')

/**
 * Platform Pages (For Marketing)
 */
const ShopifyPlus = () => import(/* webpackChunkName: "ShopifyPlus" */ './pages/Platforms/ShopifyPlus.vue')
const Magento = () => import(/* webpackChunkName: "Magento" */ './pages/Platforms/Magento.vue')
const BigCommerce = () => import(/* webpackChunkName: "BigCommerce" */ './pages/Platforms/BigCommerce.vue')

export const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      component: Home,
      path: '/',
    },
    {
      path: '/contact',
      name: 'Contact',
      component: Contact,
      beforeEnter() {
        location.href = 'https://form.typeform.com/to/x3bPomMT'
      },
    },
    {
      component: Developers,
      path: '/developers',
      name: 'Developers',
    },
    {
      component: Apps,
      path: '/apps',
      name: 'Apps',
    },
    {
      component: Pagespeed,
      path: '/pagespeed',
    },
    {
      component: PaidMedia,
      path: '/paid-media',
    },
    {
      component: Portfolio,
      path: '/case-studies',
      name: 'portfolio',
    },
    {
      component: CaseStudy,
      path: '/clients/:client/case-studies/:handle',
      name: 'case-study',
    },
    {
      component: Client,
      path: '/clients/:handle',
      name: 'client',
    },
    {
      component: ShopifyPlus,
      path: '/platforms/shopify-plus',
      name: 'shopifyplus',
    },
    {
      component: Magento,
      path: '/platforms/magento',
      name: 'magento',
    },
    {
      component: BigCommerce,
      path: '/platforms/bigcommerce',
      name: 'bigcommerce',
    },
    {
      component: NotFound,
      path: '/:pathMatch(.*)*'
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      }
    }

    return { top: 0, behavior: 'smooth', }
  }
});

router.beforeEach((to, from, next) => {
  next();
});