<template>
  <div class="bg-gray-100">
    <div class="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
      <p class="text-center text-sm font-semibold uppercase text-gray-500 tracking-wide">
        {{ small_title }}
      </p>
      <div
        :class="'mt-6 content-center grid grid-cols-2 gap-8 md:grid-cols-' + logos.length"
      >
        <div v-for="logo in logos" :key="logo.src" class="col-span-1 flex justify-center">
          <a :href="logo.website + '?ref=palize.com'" target="_blank" tabindex="0">
            <img class="h-12" :src="logo.src" :alt="logo.alt" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    small_title: "",
    logos: [],
  },
};
</script>
