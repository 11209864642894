import { createApp } from "vue";
import { router } from "./router";

import * as clientInterface from './data/interface';

import App from "./App.vue";
import './scss/index.scss';

const app = createApp(App);

app.use(router);
app.provide('clientInterface', clientInterface);

app.mount("#app");